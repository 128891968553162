@import '~/src/styles/variables.scss';

.wrapper{
	width: 100%;
	display: flex;
	padding: 1rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
	border-radius: 1rem;
	background: #FFF;
	box-shadow: 0px 4px 20px 0px rgba(212, 217, 222, 0.25);
	a{
		text-decoration: none;
	}
}
.mobileFlex{
	display: contents;
}
.mobileBtns{
	display: contents;
}
.img{
	min-width: 4.25rem;
	width: 4.25rem;
	height: 4.25rem;
	border-radius: 100%;
	overflow: hidden;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.flex{
	display: flex;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
}
.content{
	width: 100%;
	p{
		color: $gray-500;
		font-size: 0.875rem;
		font-style: normal;
		line-height: 1.125rem; /* 128.571% */
		margin: 0;
		padding: 0;
		margin-top: 0.25rem;
	}
	.titleFlex{
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin: 0;
		.title{
			color: $gray-900;
			font-size: 1.25rem;
			font-style: normal;
			font-weight: 600;
			line-height: 1.75rem;
		}
		span{
			display: flex;
			padding: 0.0625rem 0.375rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 0.625rem;
			border-radius: 0.25rem;
			background: #EDF1FF;
			color: #436CFF;
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 1.375rem;
		}
	}
}
.line{
	width: 100%;
	height: 1px;
	background: $gray-100;
}
.stats{
	display: flex;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
	padding-top: 1rem;
	border-top: 1px solid $gray-100;
	div{
		display: flex;
		padding: 0.75rem 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.125rem;
		flex: 1 0 0;
		border-radius: 0.5rem;
		border: 1px solid $gray-100;
		span{
			color: $gray-500;
			font-size: 0.875rem;
			font-style: normal;
			line-height: 1rem; /* 114.286% */
			margin: 0;
		}
		p{
			margin: 0;
			color: $gray-900;
			font-size: 1.125rem;
			font-weight: 600;
			line-height: 1.625rem;
		}
	}
}
.button{
	width: fit-content;
	padding: 1rem 1.5rem;
	color: $gray-900 !important;
	background: #F3F3F3 !important;
	border: none !important;
}
.removeBtn{
	width: fit-content;
	padding: 1rem 1.5rem;
}
.formats{
	:global{
		.Dropdown-control{
			display: flex;
			height: 3rem;
			padding: 1rem 1.5rem;
			justify-content: center;
			align-items: center;
			gap: 0.5rem;
			border-radius: 2.5rem;
			border: 1px solid $gray-100;
		}
		.Dropdown-menu{
			border-radius: 0.5rem;
			border: 1px solid $gray-100;
			background: #fff;
			top: 115%;
		}
		.Dropdown-option, .Dropdown-option.is-selected{
			background-color: #fff;
			padding: .75rem;
			color: $gray-800;
			font-size: 1rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1.5rem; /* 150% */
			letter-spacing: 0.02rem;
			&:hover{
				background: #EDF1FF;
			}
		}
		.Dropdown-placeholder{
			color: $gray-900;
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.25rem; /* 125% */
			min-width: 7.5rem;
			&::before{
				content: 'Формат размещения';
				display: block;
				color: $gray-500;
				font-size: 0.75rem;
				font-weight: 400;
				line-height: 0.875rem; /* 116.667% */
			}
		}
	}
}

@media (max-width: 1260px) {
	.wrapper{
		padding: .75rem;
		gap: .75rem;
	}
	.img{
		min-width: 2.875rem;
		width: 2.875rem;
		height: 2.875rem;
	}
	.flex{
		gap: .5rem;
	}
	.content{
		p{
			font-size: 0.625rem;
			line-height: .75rem; /* 128.571% */
		}
		.titleFlex{
			gap: 0.125rem;
			flex-direction: column-reverse;
			align-items: start;
			.title{
				font-size: .875rem;
				line-height: 1.125rem;
			}
			span{
				padding: 0.125rem 0.25rem;
				font-size: 0.625rem;
				line-height: .75rem;
			}
		}
	}
	.stats{
		gap: .75rem;
		padding-top: .75rem;
		div{
			padding: 0.25rem 1rem;
			span{
				font-size: 0.625rem;
				line-height: .75rem; /* 114.286% */
			}
			p{
				font-size: 1rem;
				line-height: 1.25rem;
			}
		}
	}
	.button, .removeBtn{
		padding: 0.5rem 0.75rem;
		height: 2.25rem;
	}
	.formats{
		:global{
			.Dropdown-control{
				height: 2.25rem;
				padding: 0.25rem 0.75rem;
			}
			.Dropdown-option, .Dropdown-option.is-selected{
				padding: .5rem;
				font-size: .75rem;
				line-height: 1rem; /* 125% */
			}
			.Dropdown-placeholder{
				font-size: .75rem;
				line-height: 1rem; /* 125% */
				min-width: 4.75rem;
				&::before{
					font-size: 0.625rem;
					line-height: 0.75rem; /* 116.667% */
				}
			}
		}
	}	
}

@media (max-width: 620px) {
	.flex{
		display: contents;
	}
	.mobileFlex{
		display: flex;
		align-items: center;
		gap: 1rem;
		align-self: stretch;
		order: 0;
	}
	.mobileBtns{
		display: flex;
		align-items: center;
		gap: .5rem;
		align-self: stretch;
		order: 2;
		width: 100%;
	}
	.content{
		p{
			font-size: 1rem;
			line-height: 1.25rem; /* 128.571% */
		}
		.titleFlex{
			.title{
				font-size: 1.25rem;
				line-height: 1.5rem;
			}
			span{
				padding: 0.25rem 0.5rem;
				font-size: 1rem;
				line-height: 1.25rem;
			}
		}
	}
	.stats{
		order: 1;
		flex-wrap: wrap;
		div{
			padding: 0.5rem 1rem;
			min-width: calc(50% - .75rem);
			span{
				font-size: 0.875rem;
				line-height: 1rem; /* 114.286% */
			}
		}
	}
	.button, .removeBtn{
		padding: 1rem 2rem;
		height: 3.25rem;
		font-size: 1rem;
	}
	.formats{
		flex-grow: 1;
		:global{
			.Dropdown-control{
				height: 3.25rem;
				padding: 0.5rem 1.5rem;
			}
			.Dropdown-option, .Dropdown-option.is-selected{
				padding: .5rem;
				font-size: .75rem;
				line-height: 1rem; /* 125% */
			}
			.Dropdown-placeholder{
				font-size: 1rem;
				line-height: 1.25rem; /* 125% */
				flex-grow: 1;
				&::before{
					font-size: 0.875rem;
					line-height: 1; /* 116.667% */
				}
			}
		}
	}	
}

@media (max-width: 420px) {
	.content{
		p{
			font-size: .875rem;
			line-height: 1rem;
		}
		.titleFlex{
			.title{
				font-size: 1rem;
				line-height: 1.25rem;
			}
			span{
				padding: 0.25rem 0.5rem;
				font-size: .75rem;
				line-height: 0.875rem;
			}
		}
	}
	.stats{
		div{
			span{
				font-size: 0.75rem;
				line-height: .875rem; /* 114.286% */
			}
			p{
				font-size: 0.875rem;
				line-height: 1rem; 
			}
		}
	}
	.button, .removeBtn{
		font-size: .875rem;
	}
	.formats{
		:global{
			.Dropdown-control{
				padding: 0.69rem 1.5rem;
			}
			.Dropdown-placeholder{
				font-size: .875rem;
				line-height: 1rem; /* 125% */
				&::before{
					font-size: 0.75rem;
					line-height: .875rem; /* 116.667% */
				}
			}
		}
	}	
}