@import '/src/styles/variables.scss';

.wrapper{
	width: 100vw;
	height: 100vh;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: $publisher-bg-color;
	display: flex;
	align-items: center;
	background-image: url('~/public/images/publisherBG.svg');
	.flex{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		h2{
			color: $text-color;
			font-family: 'SF Bold';
			font-size: 5.55556rem;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin: 0;
			padding: 0;
			span{
				color: $main-blue-color;
				display: block;
			}
		}
		p{
			color: $gray-700;
			font-size: 2rem;
			margin-top: 2.2rem;
		}
	}
	.img{
		max-width: 50%;
		width: 100%;
		position: relative;
		display: block;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	@media (max-width: 1260px) {
		.flex{
			h2{
				font-size: 2.75rem;
			}
			p{
				font-size: 1.25rem;
				margin-top: 1.5rem;
			}
		}
	}
	@media (max-width: 620px) {
		.flex{
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			gap: 2.25rem;
			h2{
				font-size: 2.25rem;
				text-align: center;
			}
			p{
				font-size: 1rem;
				text-align: center;
				margin-top: 1rem;
			}
		}
		.img{
			max-width: 22.625rem;
			width: 100%;
		}
	}
	@media (max-width: 420px) {
		.flex{
			h2{
				font-size: 2rem;
			}
			p{
				font-size: .875rem;
			}
		}
	}
}