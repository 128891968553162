@import '~/src/styles/variables.scss';

.wrapper{
	display: flex;
	padding: 0.75rem;
	align-items: center;
	gap: 0.75rem;
	align-self: stretch;
	border-radius: 0.5rem;
	border: 1px solid $gray-100;
	color: $gray-900;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem; /* 150% */
	letter-spacing: 0.02rem;
	.icon{
		display: flex;
		width: 3rem;
		height: 3rem;
		justify-content: center;
		align-items: center;
		border-radius: 7.6875rem;
		background: #F3F3F3;
		svg{
			width: 1.125rem;
			height: 1.125rem;
			color: $gray-500;
		}
	}
}