@import '~/src/styles/variables.scss';

.ReactModal{
	&__Overlay {
		z-index: 100;
		background: rgba(20, 21, 22, 0.70) !important;
	}
	&__Content {
		width: fit-content;
		padding: 1rem !important;
		border-radius: 1rem !important;
		background: #FFF !important;
		box-shadow: 0px 4px 20px 0px rgba(212, 217, 222, 0.25) !important;
		border: none !important;
		left: 50% !important;
		top: 50% !important;
		height: fit-content;
		transform: translate(-50%, -50%);
		max-width: calc(100% - 2rem);
	}
	&__Header{
		display: flex;
		align-items: flex-start;
		gap: 1rem;
		align-self: stretch;
		justify-content: space-between;
		color: $gray-900;
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 0.025rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid $gray-100;
		p{
			padding: 0;
			margin: 0;
		}
		button{
			padding: 0;
			border: none;
			background: none;
			cursor: pointer;
			color: #4F5157;
			&:hover{
				transition: .4s color;
				color: #000;
			}
		}
	}
	@media (max-width: 768px) {	
		&__Content {
			width: calc(100% - 2rem);
		}
	}
}
