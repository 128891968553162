@import '~/src/styles/variables.scss';

.wrapper{
	width: 100%;
	display: flex;
	padding: 1rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
	border-radius: 1rem;
	background: #FFF;
	border: 1px solid $gray-100;
}
.img{
	min-width: 4.25rem;
	width: 4.25rem;
	height: 4.25rem;
	border-radius: 100%;
	overflow: hidden;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.flex{
	display: flex;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
}
.content{
	width: 100%;
	p{
		color: $gray-500;
		font-size: 0.875rem;
		font-style: normal;
		line-height: 1.125rem; /* 128.571% */
		margin: 0;
		padding: 0;
		margin-top: 0.25rem;
	}
	h2{
		color: $gray-900;
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.75rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin: 0;
		span{
			display: flex;
			padding: 0.0625rem 0.375rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 0.625rem;
			border-radius: 0.25rem;
			background: #EDF1FF;
			color: #436CFF;
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 1.375rem;
		}
	}
}
.line{
	width: 100%;
	height: 1px;
	background: $gray-100;
}
.stats{
	display: flex;
	align-items: stretch;
	gap: 1rem;
	align-self: stretch;
	padding-top: 1rem;
	border-top: 1px solid $gray-100;
	div{
		display: flex;
		padding: 0.75rem .5rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.125rem;
		flex: 1;
		border-radius: 0.5rem;
		border: 1px solid $gray-100;
		span{
			color: $gray-500;
			font-size: 0.875rem;
			font-style: normal;
			line-height: 1rem; /* 114.286% */
			font-weight: 300;
			margin: 0;
			text-align: center;
		}
		p{
			margin: 0;
			color: $gray-900;
			font-size: 1.125rem;
			font-weight: 600;
			line-height: 1.625rem;
		}
	}
}
.editBtn{
	width: fit-content;
	padding: 1rem 1.5rem;
}
.removeBtn{
	width: 3rem;
	height: 3rem;
	padding: 1rem 1.5rem;
}
.formats{
	:global{
		.Dropdown-control{
			display: flex;
			height: 3rem;
			padding: 1rem 1.5rem;
			justify-content: center;
			align-items: center;
			gap: 0.5rem;
			border-radius: 2.5rem;
			border: 1px solid $gray-100;
		}
		.Dropdown-menu{
			border-radius: 0.5rem;
			border: 1px solid $gray-100;
			background: #fff;
			top: 115%;
		}
		.Dropdown-option, .Dropdown-option.is-selected{
			background-color: #fff;
			padding: .75rem;
			color: $gray-800;
			font-size: 1rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1.5rem; /* 150% */
			letter-spacing: 0.02rem;
			&:hover{
				background: #EDF1FF;
			}
		}
		.Dropdown-placeholder{
			color: $gray-900;
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.25rem; /* 125% */
			min-width: 7.5rem;
			&::before{
				content: 'Формат поста';
				display: block;
				color: $gray-500;
				font-size: 0.75rem;
				font-weight: 400;
				line-height: 0.875rem; /* 116.667% */
			}
		}
	}
}