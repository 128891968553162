@import '~/src/styles/variables.scss';

.wrapper{
	position: relative;
}

.button {
	display: flex;
	gap: .5rem;
	align-items: center;
	border: 0;
	background: none;
	padding: 0;
	.circle{
		width: 2.5rem;
		height: 2.5rem;
		padding: 0;
		border-radius: 50%;
		border-radius: 2.25rem;
		border: 1px solid #D3D4D5;
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			color: #dd8717;
			position: relative;
			object-fit: cover;
		}
	}
	.arrowDown{
		width: 1.5rem;
		height: 1.5rem;
		display: block;
	}	
	.arrowUp{
		width: 1.5rem;
		height: 1.5rem;
		display: none;
	}
	&.active{
		.arrowDown{
			display: none;
		}	
		.arrowUp{
			display: block;
		}
	}
}


.menu {
  position: absolute;
  top: calc(100% + .5rem);
  right: 0;
  width: 10rem;
	border-radius: 0.5rem;
	border: 1px solid $gray-100;
	background:  #FFF;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
	overflow: hidden;
	
	&.active {
		opacity: 1;
		visibility: visible;
	}
}

.list {
  margin: 0;
  width: 100%;
  padding: 0;
  list-style-type: none;
}

.item {
	display: flex;
	padding: 0.75rem;
	align-items: center;
	gap: 0.75rem;
	align-self: stretch;
  color: $gray-800;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
	letter-spacing: 0.02rem;
	transition: .3s;
  cursor: pointer;
	&:hover{
		background:#EDF1FF;
	}
}

