@import '~/src/styles/variables.scss';

.wrapper{
	width: 100vw;
	border-radius: 4.44444rem 4.44444rem 0rem 0rem;
	background: $white-color;
	z-index: 10;
	position: relative;
	overflow-x: hidden;
	.flex{
		display: flex;
		flex-direction: column;
		padding-top: 11.3rem;
		padding-bottom: 4.1rem;
		position: relative;
		z-index: 20;
		.info{
			font-size: 2rem;
			font-weight: 400;
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 1.1rem;
			align-self: flex-start;
			opacity: 0;
			transform: translateX(-100%);
			position: relative;
			z-index: 10;
			span{
				position: relative;
				&::before{
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background-color: rgba(255, 255, 255, 0.3);
					z-index: -1;
					backdrop-filter: blur(3px);
					border-radius: 10px;
				}
			}
			img{
				width: 15rem;
				height: 15rem;
			}
			&:nth-child(2){
				align-self: flex-end;
				flex-direction: row-reverse;
				text-align: right;
				margin-top: 18.9rem;
				margin-bottom: 16.2rem;
				transform: translateX(100%);
			}
		}
	}
	@media (max-width: 1260px){
		border-radius: 2.5rem 2.5rem 0rem 0rem;
		.flex{
			padding-top: 9.69rem;
			padding-bottom: 6.94rem;
			.info{
				font-size: 1.25rem;
				gap: 1.25rem;
				img{
					width: 9.06238rem;
					height: 9.6875rem;
				}
				&:nth-child(2){
					margin-top: 14.75rem;
					margin-bottom: 14.75rem;
				}
			}
		}
	}
	@media (max-width: 768px) {
		.flex{
			padding-top: 4.91rem;
			padding-bottom: 1rem;
			.info{
				font-size: 0.875rem;
				gap: 1.25rem;
				transform: translateX(0);
				opacity: 1;
				img{
					width: 6.25rem;
					height: 6.68113rem;
				}
				&:nth-child(2){
					margin-top: 12.35rem;
					margin-bottom: 10.06rem;
					transform: translateX(0);
				}
			}
		}
		.pathMobile{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}
	@media (max-width: 420px) {
		.flex{
			padding-top: 4.72rem;
			padding-bottom: 1rem;
			.info{
				img{
					width: 5rem;
				}
				&:nth-child(2){
					margin-top: 7.94rem;
					margin-bottom: 10.7rem;
				}
			}
		}
	}
}