@import '~/src/styles/variables.scss';

.inputsWrapper{
	display: flex;
	padding: .75rem;
	align-items: center;
	gap: 0.5rem;
	align-self: stretch;
	border-radius: 0.5rem;
	border: 1px solid $gray-100;
	background: #FFF;
	width: 100%;
	input{
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		padding: 0;
		letter-spacing: 0.02rem;
		border: 0;
		background: none;
		outline: none;
		width: 100%;
		line-height: 1.5;
	}
}

.calendarGroup{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5rem;
	:global(.react-datepicker-wrapper){
		width: 100%;
	}
	.header{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.375rem;
		letter-spacing: 0.0175rem;
		color: $gray-800;
		svg{
			width: 1.125rem;
			height: 1.125rem;
		}
	}
}

.wrapper{
	border-radius: 0.5rem !important;
	border: 1px solid $gray-300 !important;
	background: #FFF !important;
	box-shadow: 0px 4px 20px 0px rgba(212, 217, 222, 0.25) !important;
	:global(.react-datepicker__header){
		padding: 0;
		background: #fff;
		border: 0;
		border-top-right-radius: .5rem;
		border-top-left-radius: .5rem;
	}
	:global(.react-datepicker__day-names){
		margin-bottom: 0;
		padding: 0.5rem 0.75rem;
		padding-bottom: 0;
	}
	:global(.react-datepicker__month){
		margin: 0;
		padding: 0.5rem 0.75rem;
		padding-top: 0;
	}
	:global(.react-datepicker__day-name){
		display: inline-flex;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0.5rem 0.625rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		color: $gray-400;
		text-align: center;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.375rem; /* 157.143% */
		letter-spacing: 0.0175rem;
		margin: 0;
	}
	:global(.react-datepicker__day){
		display: inline-flex;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0.5rem 0.625rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		color: $gray-800;
		text-align: center;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.375rem; /* 157.143% */
		letter-spacing: 0.0175rem;
		margin: 0;
		border-radius: 50%;
		&:hover{
			background: #EDF1FF;
			color: #436CFF;
			border-radius: 50%;
		}
	}
	:global(.react-datepicker__day--keyboard-selected){
		background-color: #436CFF;
		color: #fff;
	}
	.btns{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.75rem;
		gap: 0.375rem;
		border-top: 1px solid $gray-100;
	}
}

.headerWrapper{
	display: flex;
	border-top-right-radius: .5rem;
	border-top-left-radius: .5rem;
	justify-content: space-between;
	align-items: center;
	gap: 0.25rem;
	color: $gray-800;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.375rem; /* 157.143% */
	letter-spacing: 0.0175rem;
	width: 19rem;
	padding: 0.75rem;
	justify-content: center;
	align-items: center;
	background-color: #FFF;
	border-bottom: 1px solid $gray-100;
	button{
		border: 0;
		background: none;
		padding: 0;
		&:first-child{
			margin-right: auto;
		}
		&:last-child{
			margin-left: auto;
		}
	}
}