@import '~/src/styles/variables.scss';

.wrapper{
	display: flex;
	background: #F4F8FE;
	width: 100vw;
	height: 100vh;
}
.content{
	 display: flex;
	 flex-direction: column;
	 width: 100%;
	 height: 100%;
	 max-width: calc(100% - 19.8125rem);
}
.scroll{
	flex-grow: 1;
	width: 100%;
	height: 100%;
	padding: 1.25rem;
}
.header{
	width: 100%;
	display: flex;
	padding: 1.25rem;
	justify-content: space-between;
	align-items: center;
	gap: 1.25rem;
	flex-shrink: 0;
	color: $gray-800;
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem; /* 150% */
	letter-spacing: 0.02rem;
	background: #fff;
	border-bottom: 1px solid $gray-100;
}