@import '~/src/styles/variables.scss';

.grid{
	display: flex;
	flex-direction: column;
	gap: 1rem;
	max-height: 100%;
	overflow-y: auto;
}
.cardHeader{
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $gray-800;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.025rem;
	.btn{
		padding: 1rem 1.5rem;
		width: fit-content;
	}
}
.count{
	display: inline-flex;
	height: 1.5rem;
	padding: 0.25rem 0.5rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 0.25rem;
	background: $gray-100;
	color: $gray-800;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.375rem; /* 157.143% */
	letter-spacing: 0.0175rem;
	margin-left: 0.5rem;
}