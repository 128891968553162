@import '~/src/styles/variables.scss';

.selectGroup{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5rem;
	.header{
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.375rem;
		letter-spacing: 0.0175rem;
		color: $gray-800;
		svg{
			width: 1.125rem;
			height: 1.125rem;
		}
	}
}
