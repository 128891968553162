$publisher-bg-color: #F4F8FE;
$seller-bg-color: #FEE9E9;
$text-color: #141516;
$mute-color: #7B7D81;
$white-color: #fff;
$main-blue-color: #436CFF;
$main-red-color: #EE1F1F;
$gray-900: #141516;
$gray-800: #4F5157;
$gray-700: #65686C;
$gray-500: #919396;
$gray-400: #A7A8AB;
$gray-300: #BDBEC0;
$gray-200: #D3D4D5;
$gray-100: #E9EAEA;