@import '~/src/styles/variables.scss';

.slider{
	margin-top: 3rem;
	margin-bottom: 0.75rem;
	padding: 0 10px !important;
	border: none !important;
	box-shadow: none !important;
	:global{
		.bar-inner{
			background-color: var(--blue-900, #436CFF) !important;
			border: none !important;
			box-shadow: none !important;
		}
		.thumb::before{
			width: 16px !important;
			height: 16px !important;
			box-sizing: border-box;
			border: 4px solid $main-blue-color !important;
			box-shadow: none !important;
			margin: -6px -6px !important;
		}
		.caption{
			display: flex !important;
			bottom: 25px !important;
			span{
				border: none !important;
				box-shadow: none !important;
				border-radius: 0.25rem !important;
				background: $gray-800 !important;
				min-width: auto !important;
				height: auto !important; 
				line-height: 1.57 !important;
				padding: 0rem 0.25rem !important;
				font-size: 0.875rem !important;
			}
		}
		.bar-right, .bar-left{
			background-color: #EDF1FF !important;
			border: none !important;
			box-shadow: none !important;
			padding: 2px 0 !important;
		}
	}
}

.inputsWrapper{
	display: flex;
	align-items: center;
	border-radius: 0.5rem;
	border: 1px solid $gray-100;
	background: $white-color;
	margin-top: 0.5rem;
	input{
		border: none;
		width: 100%;
		display: flex;
		padding: 0.75rem 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 0.625rem;
		flex: 1 0 0;
		font-size: 1rem;
		font-style: normal;
		line-height: 1.5rem; /* 150% */
		outline: none !important;
	}
	span{
		font-size: 1rem;
		font-style: normal;
		line-height: 1.5rem; /* 150% */
		color: $gray-500;
		background: #F3F3F3;
		padding: 0.75rem 1rem;
	}
}

.inputsGroup{
	h5{
		color: $gray-800;
		font-size: 0.875rem;
		font-style: normal;
		line-height: 1.375rem; /* 157.143% */
		font-weight: 400;
		margin: 0;
		padding: 0;
		span{
			color: $gray-500;
		}
	}
}

.wrapper{
	height: fit-content;
	display: flex;
	max-width: 25.9375rem;
	width: 100%;
	box-sizing: border-box;
	padding: 1rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	border-radius: 1rem;
	background: #FFF;
	box-shadow: 0px 4px 20px 0px rgba(212, 217, 222, 0.25);
	position: sticky;
	top: 7rem;
	h2{
		color: $gray-900;
		font-size: 1.25rem;
		font-weight: 500;
		line-height: normal;
		margin: 0;
		padding: 0;
	}
}
.line{
	width: 100%;
	height: 1px;
	background: $gray-100;
	display: block;
}
.select{
	width: 100%;
}
.btn{
	width: 100% !important;
}
.resetBtn{
	color: $gray-900 !important;
	background: #F3F3F3 !important;
	border: none !important;
	width: 100% !important;
}
.graphLines{
	display: flex;
	width: 100%;
	height: 2.25rem;
	margin-bottom: -3.25rem;
	justify-content: space-between;
	align-items: flex-end;
	span{
		width: 0.125rem;
		display: block;
		background: #D9E2FF;
	}
}

@media (max-width: 1260px) {	
	.slider{
		margin-top: 2rem;
		:global{
			.thumb::before{
				width: 12px !important;
				height: 12px !important;
				border: 3px solid $main-blue-color !important;
				margin: -5px -5px !important;
			}
			.caption{
				bottom: 16px !important;
				span{
					padding: 0.25rem !important;
					line-height: 0.75 !important;
					font-size: 0.625rem !important;
				}
			}
			.bar-right, .bar-left{
				background-color: #EDF1FF !important;
				border: none !important;
				box-shadow: none !important;
				padding: 2px 0 !important;
			}
		}
	}

	.inputsWrapper{
		input{
			padding: 0.5rem;
			font-size: .75rem;
			line-height: 1rem; /* 150% */
		}
		span{
			font-size: .75rem;
			line-height: 1rem; /* 150% */
			padding: 0.5rem;
		}
	}

	.inputsGroup{
		h5{
			font-size: 0.625rem;
			line-height: .75rem; /* 157.143% */
		}
	}

	.wrapper{
		max-width: 16.8125rem;
		padding: .75rem;
		gap: .5rem;
		top: 4rem;
		h2{
			font-size: .875rem;
		}
	}

	.resetBtn{
		svg{
			width: 14px;
			height: 14px;
		}
	}
	
	.graphLines{
		height: 1.5rem;
		margin-bottom: -2.25rem;
		span{
			width: 0.13rem;
		}
	}
}	


@media (max-width: 820px) {	
	.slider{
		margin-top: 3.5rem;
		:global{
			.thumb::before{
				width: 24px !important;
				height: 24px !important;
				border: 6px solid $main-blue-color !important;
				margin: -11px -11px !important;
			}
			.caption{
				bottom: 40px !important;
				span{
					padding: 0.25rem .5rem !important;
					line-height: 1rem !important;
					font-size: 0.875rem !important;
				}
			}
			.bar-right, .bar-left{
				padding: 2px 0 !important;
			}
		}
	}

	.inputsWrapper{
		input{
			padding: 1rem;
			font-size: 1rem;
			line-height: 1.25rem; /* 150% */
		}
		span{
			font-size: 1rem;
			line-height: 1.25rem; /* 150% */
			padding: 1rem;
		}
	}

	.inputsGroup{
		h5{
			font-size: 1rem;
			line-height: 1.25rem; /* 157.143% */
		}
	}

	.wrapper{
		max-width: 100%;
		padding: 0;
		gap: 1rem;
		position: relative;
		background: none;
		box-shadow: none;
		border-radius: 0;
		border: 0;
		top: 0;
		margin-top: 1.5rem;
	}

	.resetBtn{
		svg{
			width: 1.125rem;
			height: 1.125rem;
		}
	}
	
	.graphLines{
		height: 2rem;
		margin-bottom: -3.5rem;
		margin-top: 1rem;
		span{
			width: 0.13rem;
		}
	}
}	