@import '~/src/styles/variables.scss';


.screen{
	.successWrapper{
		height: 100vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		gap: 1.44rem;
		opacity: 0;
		animation: opacity 1s ease-in;
		h2{
			color: $text-color;
			text-align: center;
			font-family: "SF Semibold";
			font-size: 2.22222rem;
			font-weight: 600;
			padding: 0;
			margin: 0;
			margin-top: -14rem;
		}
		p{
			font-size: 1.11111rem;
			padding: 0;
			margin: 0;
		}
		a{
			position: relative;
			text-decoration: none;
			width: fit-content;
			border-radius: 2.22222rem;
			background: $text-color;
			display: flex;
			padding: 0.88889rem 3.33333rem;
			align-items: flex-start;
			gap: 0.55556rem;
			color: #FFF;
			font-family: "SF Medium";
			font-size: 1.11111rem;
			transition: .4s background-color;
			cursor: pointer;
			&:hover{	
				background-color: #436CFF;
			}
		}
		img{
			width: 100%;
			position: absolute;
			bottom: 14rem;
			left: 0;
			right: 0;
			pointer-events: none;
		}
		.plane{
			position: absolute;
			height: 13rem;
			left: 50%;
			bottom: 10rem;
			width: 100%;
			transform: translateX(-38%);
			pointer-events: none;
			animation: fromLeft 1s ease-in;
			[class="lf-player-container"]{
				height: 100%;
			}
		}
	}
	&.sended{
		.successWrapper{
			transition: opacity .5s;
			opacity: 1;
		}
	}
}


.checkbox{
	margin-bottom: 1rem;
	input{
		display: none;
		&:checked + label{
			.checkboxIcon{
				&:first-child{
					display: none;
				}
				&:nth-child(2){
					display: block;
				}
			}
		}
	}
	&.sm{
		label{
			font-size: .875rem;
		}
	}
	label{
		display: flex;
		align-items: center;
		gap: 0.5rem;
		color: $gray-800;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.5rem;
		letter-spacing: 0.02rem;
		cursor: pointer;
		a{
			text-decoration: none;
			color: $main-blue-color;
		}
		.checkboxIcon{
			min-width: 20px;
			min-height: 20px;
			&:first-child{
				display: block;
				color: $gray-500;
			}
			&:nth-child(2){
				display: none;
				color: #436CFF;
			}
		}
		&:hover{
			transition: .4s color;
			color: $gray-700;
			.checkboxIcon{
				&:first-child{
					transition: .4s color;
					color: $gray-400;
				}
				&:last-child{
					transition: .4s color;
					color: #698AFF;
				}
			}
		}
	}
}

.wrapper{
	padding-bottom: 2rem;
	padding-top: 9.5rem;
}

.title{
	margin: 0;
	color: $gray-900;
	font-size: 2.25rem;
	font-weight: 600;
}
.subtitle{
	margin: 0;
	color: $gray-500;
	font-size: 1.25rem;
	font-weight: 400;
	margin-top: 0.75rem;
}
.flex{
	display: flex;
	gap: 1.25rem;
	margin-top: 2rem;
}

.cardsWrapper{
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.productCard{
	height: fit-content;
	display: flex;
	width: 100%;
	box-sizing: border-box;
	padding: 1rem;
	flex-direction: column;
	align-items: flex-start;
	gap: .75rem;
	border-radius: 1rem;
	background: #FFF;
	box-shadow: 0px 4px 20px 0px rgba(212, 217, 222, 0.25);
	.productTitle{
		color: $gray-900;
		font-size: 1rem;
		font-family: 'SF Semibold';
		line-height: normal;
		margin: 0;
		padding: 0;
	}
	.productPrice{
		color: $gray-900;
		font-size: 1rem;
		font-weight: 500;
		line-height: normal;
		margin: 0;
		padding: 0;
		span{
			color: #888;
			display: block;
		}
	}
	.productFormat{
		color: $gray-900;
		font-size: 1rem;
		font-weight: 500;
		line-height: normal;
		margin: 0;
		padding: 0;
		span{
			color: #888;
			display: block;
		}
	}
	.productBtns{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.removeBtn{
		border: none;
		background: none;
		cursor: pointer;	
	}
}

.table{
	flex-grow: 1;
	box-sizing: border-box;
	background: #FFF;
	border-radius: 1rem;
	background: #FFF;
	box-shadow: 0px 4px 20px 0px rgba(212, 217, 222, 0.25);
	height: fit-content;
	padding: 0.5rem 0;
	border-radius: .5rem;
	overflow: hidden;
	thead th{
		text-align: left;
		padding: 0.75rem 1rem;
		background: #F4F8FE;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.5rem;
		letter-spacing: 0.02rem;
	}
	tbody td{
		padding: 0.75rem 1rem;
	}
	tbody tr{
		border-top: 1px solid $gray-100;
	}
	.resultPrice td{
		padding: 1rem;
	}
	.removeBtn{
		border: none;
		background: none;
		cursor: pointer;
		
	}
}

.cardWrapper{
	height: fit-content;
	display: flex;
	max-width: 25.9375rem;
	width: 100%;
	box-sizing: border-box;
	padding: 1rem;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	border-radius: 1rem;
	background: #FFF;
	box-shadow: 0px 4px 20px 0px rgba(212, 217, 222, 0.25);
	position: sticky;
	top: 7rem;
	h5{
		color: $gray-900;
		font-size: 1.5rem;
		font-weight: 500;
		line-height: normal;
		margin: 0;
		padding: 0;
	}
	.line{
		width: 100%;
		height: 1px;
		background: $gray-100;
		display: block;
		margin: 1rem 0;
	}
	.price{
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.5rem;
		font-weight: 500;
		span:first-child{
			color: #888;
		}
	}
	.btn{
		width: 100%;
	}
	.input{
		width: 100%;
		& > div > span{
			color: #000;
		}
	}
	.formFlex{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 1rem;
	}
}


@media (max-width: 1440px) {
	.wrapper{
		padding-top: 5.13rem;
	}
	.flex{
		margin-top: 1rem;
	}
	.title{
		font-size: 1.25rem;
	}
	.cardWrapper{
		max-width: 16.8125rem;
		padding: .75rem;
		gap: .5rem;
		top: 4rem;
		h2{
			font-size: .875rem;
		}
		.input{
			gap: .25rem;
			input{
				font-size: .75rem;
				line-height: 1rem; /* 150% */
			}
			span{
				font-size: .75rem;
				line-height: 1rem; /* 150% */
				padding: 0.5rem;
			}
		}
		.price{
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 1rem;
			font-weight: 500;
			span:first-child{
				color: #888;
			}
		}
	}	

	.table{
		thead th{
			text-align: left;
			padding: 0.5rem;
			font-size: .75rem;
			line-height: 1rem;
		}
		tbody td{
			padding: 0.5rem;
			font-size: 0.75rem;
			line-height: 1rem;
		}
	}
}


@media (max-width: 768px) {
	.flex{
		flex-direction: column;
	}
	.cardWrapper{
		max-width: 100%;
	}	
}


@media (max-width: 1260px){
	.successWrapper{
		gap: 1rem;
		h2{
			font-size: 1.5rem;
		}
		p{
			font-size: .75rem;
		}
		a{
			border-radius: 2.5rem;
			padding: 0.5rem 2rem;
			font-size: 1rem;
			line-height: 1.5rem;
		}
		img{
			bottom: 7rem;
		}
		.plane{
			height: 9.33563rem;
			bottom: 3.25rem;
		}
	}
}