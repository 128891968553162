@import '~/src/styles/variables.scss';

.wrapper{
	display: flex;
	align-items: flex-start;
	gap: 0.5rem;
	overflow-x: auto;
	-ms-overflow-style: none; 
  scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

.tab{
	width: fit-content;
	display: flex;
	height: 2.25rem;
	padding: 1rem;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	color: $gray-800;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.375rem;
	letter-spacing: 0.0175rem;
	transition: .2s;
	border-radius: 0.5rem;
	border: 1px solid $gray-100;
	background: #FFF;
	white-space: nowrap;
	cursor: pointer;
	.count{
		color: $main-blue-color;
		font-size: 0.75rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.375rem;
		letter-spacing: 0.0175rem;
		display: flex;
		height: 1.125rem;
		padding: 0.25rem 0.375rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		border-radius: 0.25rem;
		background: #EDF1FF;
		margin-left: auto;
	}
	&:hover{
		background: #EDF1FF;
	}
	&.active{
		background: $main-blue-color;
		color: #fff;
		border-color: $main-blue-color;
		.count{
			background: #7C99FF;
			color: #fff;
		}
	}
}