@import '~/src/styles/variables.scss';

@keyframes spin {
	0%{transform: rotate(0deg);}
	100%{transform: rotate(360deg);}
}

@keyframes fadeIn {
	0%{transform: scale(0.4); opacity: 0;}
	100%{transform: scale(1); opacity: 1;}
}

.loader{
	animation: spin 1s infinite;
}

.fetching{
	.iconWrapper{
		animation: fadeIn .4s;
	}
}

.wrapper{
	cursor: pointer;
	display: flex;
	background: none;
	border: none;
	width: 18.75rem;
	height: 3rem;
	padding: 1rem 3.75rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	flex-shrink: 0;
	border-radius: 2.5rem;
	transition: .4s;
	font-family: 'SF Medium';
	font-size: 1rem;
	&.primary{
		background: $main-blue-color;
		color: $white-color;
		&:hover{
			background: #698AFF;
		}
		&:disabled{
			background: #A1B6FF;
			cursor: default;
		}
	}
	&.secondary{
		background: #fff;
		color: $gray-800;
		border: 1px solid $gray-100;
		&:disabled{
			color: $gray-200;
			cursor: default;
		}
	}
	&.small{
		padding: 0 1rem;
		height: 2.25rem;
		gap: 0.5rem;
		font-size: .875rem;
		width: fit-content;
	}
	@media (max-width: 1260px) {
		height: 2rem;
		padding: 0.5rem 0rem;
		font-size: 0.75rem;
		line-height: 1rem;
		gap: .25rem;
	}
	@media (max-width: 768px) {
		height: 3.5rem;
		padding: 1rem 0rem;
		font-size: 1rem;
		line-height: 1.25rem;
	}
}