.wrapper{
	margin-top: 5.5rem;
	margin-bottom: 5.5rem;
	h2{
		margin-bottom: 3rem;
		margin-top: 4rem;
		font-size: 2.4rem;
	}
	p+p {
		margin-top: 2.3rem;
	}
}