@import '~/src/styles/variables.scss';

.inputsWrapper{
	display: flex;
	padding: 12px;
	align-items: center;
	gap: 0.5rem;
	align-self: stretch;
	border-radius: 0.5rem;
	border: 1px solid $gray-100;
	background: #FFF;
	input{
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		padding: 0;
		letter-spacing: 0.02rem;
		border: 0;
		background: none;
		outline: none;
		width: 5.8125rem;
	}
}


.wrapper{
	border-radius: 0.5rem !important;
	border: 1px solid $gray-300 !important;
	background: #FFF !important;
	box-shadow: 0px 4px 20px 0px rgba(212, 217, 222, 0.25) !important;
	:global(.react-datepicker__header){
		padding: 0;
		background: #fff;
		border: 0;
		border-top-right-radius: .5rem;
		border-top-left-radius: .5rem;
	}
	:global(.react-datepicker__day-names){
		margin-bottom: 0;
		padding: 0.5rem 0.75rem;
		padding-bottom: 0;
	}
	:global(.react-datepicker__month){
		margin: 0;
		padding: 0.5rem 0.75rem;
		padding-top: 0;
	}
	:global(.react-datepicker__day-name){
		display: inline-flex;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0.5rem 0.625rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		color: $gray-400;
		text-align: center;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.375rem; /* 157.143% */
		letter-spacing: 0.0175rem;
		margin: 0;
	}
	:global(.react-datepicker__day){
		display: inline-flex;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0.5rem 0.625rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		color: $gray-800;
		text-align: center;
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 500;
		line-height: 1.375rem; /* 157.143% */
		letter-spacing: 0.0175rem;
		margin: 0;
		border-radius: 50%;
		&:hover{
			background: #EDF1FF;
			color: #436CFF;
			border-radius: 50%;
		}
	}
	:global(.react-datepicker__day--keyboard-selected){
		background-color: #436CFF;
		color: #fff;
	}
	:global(.react-datepicker__day--in-range){
		background: #EDF1FF;
		color: #436CFF;
	}
	// react-datepicker__day react-datepicker__day--009 react-datepicker__day--in-range
	:global(.react-datepicker__week 
	.react-datepicker__day--in-range:not(:last-child):not(:first-child):not(.react-datepicker__day--range-end):not(.react-datepicker__day--range-start)){
		border-radius: 0;
	}
	:global(.react-datepicker__week 
	.react-datepicker__day--in-range:last-child:not(.react-datepicker__day--range-end):not(.react-datepicker__day--range-start)){
		border-radius: 0 50% 50% 0;
	}
	:global(.react-datepicker__week:has(.react-datepicker__day--in-range):first-child:nth-last-child(2) .react-datepicker__day--in-range:last-child,
	.react-datepicker__week:has(.react-datepicker__day--in-range):first-child:nth-last-child(2) ~ .react-datepicker__week:has(.react-datepicker__day--in-range) .react-datepicker__day--in-range:last-child){
		border-radius: 0 50% 50% 0;
	}
	:global(.react-datepicker__week 
	.react-datepicker__day--in-range:first-child:not(.react-datepicker__day--range-end):not(.react-datepicker__day--range-start)){
		border-radius: 50% 0 0 50%;
	}
	:global(.react-datepicker__day--range-start){
		background-color: #436CFF;
		position: relative;
		color: #fff;
		z-index: 10;
		&:hover{
			color: #fff;
		}
		&::before{
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 50% 0 0 50%;
			background: #EDF1FF;
			display: block;
			z-index: -1;
		}
		&::after{
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background-color: #436CFF;
			display: block;
			z-index: -1;
		}
	}
	:global(.react-datepicker__day--range-end){
		background-color: #436CFF;
		color: #fff;
		position: relative;
		z-index: 10;
		&:hover{
			color: #fff;
		}
		&::before{
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 0 50% 50% 0;
			background: #EDF1FF;
			display: block;
			z-index: -1;
		}
		&::after{
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background-color: #436CFF;
			display: block;
			z-index: -1;
		}
	}
	:global(.react-datepicker__day--in-selecting-range){
		background-color: #436CFF;
		color: #fff;
	}
	:global(.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)){
		background: #EDF1FF;
		color: #436CFF;
	}
	:global(.react-datepicker__week 
	.react-datepicker__day--in-selecting-range:not(:last-child):not(:first-child):not(.react-datepicker__day--selecting-range-end):not(.react-datepicker__day--selecting-range-start)){
		border-radius: 0;
	}
	:global(.react-datepicker__week 
	.react-datepicker__day--in-selecting-range:last-child:not(.react-datepicker__day--selecting-range-end):not(.react-datepicker__day--selecting-range-start)){
		border-radius: 0 50% 50% 0;
	}
	:global(.react-datepicker__week 
	.react-datepicker__day--in-selecting-range:first-child:not(.react-datepicker__day--selecting-range-end):not(.react-datepicker__day--selecting-range-start)){
		border-radius: 50% 0 0 50%;
	}
	:global(.react-datepicker__day:not(:hover).react-datepicker__day--selecting-range-start){
		border-radius: 50% 0 0 50%;
	}
	:global(.react-datepicker__day--selecting-range-end){
		border-radius: 0 50% 50% 0 !important;
	}
	.btns{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.75rem;
		gap: 0.375rem;
		border-top: 1px solid $gray-100;
	}
}

.headerWrapper{
	display: flex;
	border-top-right-radius: .5rem;
	border-top-left-radius: .5rem;
	justify-content: space-between;
	align-items: center;
	gap: 0.25rem;
	color: $gray-800;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.375rem; /* 157.143% */
	letter-spacing: 0.0175rem;
	width: 19rem;
	padding: 0.75rem;
	justify-content: center;
	align-items: center;
	background-color: #FFF;
	border-bottom: 1px solid $gray-100;
	button{
		border: 0;
		background: none;
		padding: 0;
		&:first-child{
			margin-right: auto;
		}
		&:last-child{
			margin-left: auto;
		}
	}
}