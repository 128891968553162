@import '~/src/styles/variables.scss';

.footer{
	position: relative;
	width: 100vw;
	padding: 2.22222rem  0;
	background: $white-color;
	z-index: 20;
	border-top: 1px solid #E9EAEA;
	top: 0;
	.flex{
		display: flex;
		justify-content: space-between;
		align-items: center;
		a{
			text-decoration: none;
			display: block;
		}
		.logo img{
			width: 12.5rem;
		}
		ul{
			display: flex;
			align-items: flex-end;
			gap: 3.77778rem;
			padding: 0;
			margin: 0;
			list-style: none;
			a{
				color: $gray-700;
				font-size: 1.11111rem;
				&:hover{
					color: $text-color;
					transition: .2s;
				}
			}
		}
		.btn{
			width: 1.7778rem;
			opacity: .4;
			cursor: pointer;
			&:hover{
				opacity: 1;
				transition: .2s;
			}
		}
	}
	@media (max-width: 1260px) {
		padding: 0.5rem 0;
		.flex{
			.logo img{
				width: 8.75rem;
			}
			ul{
				gap: 2rem;
				a{
					font-size: .75rem;
				}
			}
			.btns{
				display: flex;
				align-items: center;
				gap: 1rem;
			}
			.cartBtn{
				padding: 0.5rem;
				border-radius: 2.5rem;
				font-size: .75rem;
				height: 1.75rem;
			}
			.cartIcon{
				margin-right: .3rem;
				width: 12px;
				height: 12px;
			}
			.btn{
				padding: 0.5rem 2rem;
				border-radius: 2.5rem;
				font-size: .75rem;
			}
		}
	}
	@media (max-width: 768px) {
		padding: 1rem 0;
		.flex{
			flex-wrap: wrap;
			gap: 1rem;
			.logo {
				order: 1;
					img{
					width: 7.5rem;
				}
			}
			ul{
				order: 0;
				gap: 1rem 2rem;
				flex-wrap: wrap;
				width: 100%;
				justify-content: center;
				a{
					white-space: nowrap;
					font-size: 1rem;
				}
			}
			.btn{
				order: 2;
				width: 1.5rem;
			}
		}
	}
}