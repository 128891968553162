@font-face {
	font-family: 'SF Black';
	src: url('~/public/fonts/SF-Pro-Display-Black.woff');
	font-display: swap;
}
@font-face {
	font-family: 'SF Bold';
	src: url('~/public/fonts/SF-Pro-Display-Bold.woff');
	font-display: swap;

}
@font-face {
	font-family: 'SF Heavy';
	src: url('~/public/fonts/SF-Pro-Display-Heavy.woff');
	font-display: swap;

}
@font-face {
	font-family: 'SF Light';
	src: url('~/public/fonts/SF-Pro-Display-Light.woff');
	font-display: swap;

}
@font-face {
	font-family: 'SF Medium';
	src: url('~/public/fonts/SF-Pro-Display-Medium.woff');
	font-display: swap;

}
@font-face {
	font-family: 'SF Regular';
	src: url('~/public/fonts/SF-Pro-Display-Regular.woff');
	font-display: swap;

}
@font-face {
	font-family: 'SF Semibold';
	src: url('~/public/fonts/SF-Pro-Display-Semibold.woff');
	font-display: swap;

}
@font-face {
	font-family: 'SF Thin';
	src: url('~/public/fonts/SF-Pro-Display-Thin.woff');
	font-display: swap;

}
@font-face {
	font-family: 'SF Ultralight';
	src: url('~/public/fonts/SF-Pro-Display-Ultralight.woff');
	font-display: swap;

}

body {
  margin: 0;
  font-family: 'SF Regular';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.space{
	height: 100vh;
	pointer-events: none;
}

.container{
	padding: 0 5.55556rem;
	box-sizing: border-box;
	width: 100%;
	@media (max-width: 1400px) {
		padding: 0 2.5rem;
	}
	@media (max-width: 768px) {
		padding: 0 1rem;
	}
}

.shu_1, .shu_2, .shu_3{
	opacity: 0;
}