@import '/src/styles/variables.scss';

.wrapper{
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	transition: .4s background-color;
	position: fixed;
	z-index: 0;
	.publisher{
		&__bgScene{
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: .4s;
			pointer-events: none;
		}
		&__leftBg{
			position: absolute;
			height: calc(100vh - 7rem);
			left: 0;
			top: 5rem;
			width: 100%;
			transform: translateX(-38%);
			pointer-events: none;
			z-index: 15;
			[class="lf-player-container"]{
				height: 100%;
			}
		}
		&__rightBg{
			position: absolute;
			height: calc(100vh - 7rem);
			right: 0;
			top: 5rem;
			width: 100%;
			transform: translateX(43%);
			pointer-events: none;
			z-index: 15;
			[class="lf-player-container"]{
				height: 100%;
			}
		}
		&__plane{
			position: absolute;
			height: 13rem;
			left: 50%;
			bottom: 3.3rem;
			width: 100%;
			transform: translateX(-38%);
			pointer-events: none;
			z-index: 10;
			[class="lf-player-container"]{
				height: 100%;
			}
		}
		&__planeRight{
			height: calc(100vh - 15rem);
			position: absolute;
			top: 5rem;
			right: 17rem;
			z-index: 5;
		}
		&__planeLeft{
			height: 60%;
			position: absolute;
			bottom: 8rem;
			left: 50%;
			transform: translateX(-100%);
			z-index: 5;
		}
	}

	.seller{
		&__bgScene{
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: .4s;
			pointer-events: none;
		}
		&__lottie{
			position: absolute;
			height: 80%;
			right: 0;
			left: auto;
			bottom: 0;
			width: 100%;
			pointer-events: none;
			[class="lf-player-container"]{
				height: 100%;
			}
		}
		&__bottom{
			position: absolute;
			height: 60%;
			right: 50px;
			left: auto;
			bottom: 50px;
			pointer-events: none;
		}
		&__computer{
			width: 16%;
			position: absolute;
			top: 38%;
			right: 4rem;
		}
		&__right{
			height: calc(50vh - 7rem);
			position: absolute;
			top: 5rem;
			right: 12rem;
		}
		&__left{
			height: 40%;
			position: absolute;
			bottom: 18rem;
			left: 50%;
			transform: translateX(-250%);
		}
		&__static_cloud{
			width: 8%;
			position: absolute;
			top: 38%;
			left: 4rem;
		}
		&__static_switcher{
			width: 8%;
			position: absolute;
			bottom: 6rem;
			left: 39%;
		}
		&__static_profile{
			width: 8%;
			position: absolute;
			bottom: 6rem;
			right: 6%;
		}
		&__static_notes{
			width: 8%;
			position: absolute;
			bottom: 10rem;
			left: 6%;
		}
	}
	h1{
		text-align: center;
		font-size: 5.55556rem;
		font-family: 'SF Bold';
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin-top: 2.22rem;
		max-width: 61rem;
		span{
			position: relative;
			display: inline-block;
			span{
				position: relative;
				z-index: 5;
			}
			img{
				position: absolute;
				z-index: 0 !important;
			}
		}
	}
	&.seller{
		background-color: $seller-bg-color;
		background-image: url('~/public/images/sellerBG.svg');
		h1{
			span{
				img{
					height: 4.16rem;
					bottom: 0;
					left: .22rem;
					max-width: 100%;
				}
			}
		}
		.seller__bgScene{
			opacity: 1;
		}
		
	}
	&.publisher{
		background-color: $publisher-bg-color;
		background-image: url('~/public/images/publisherBG.svg');
		h1{
			span{
				img{
					height: 5.24rem;
					bottom: 0;
					left: .22rem;
					max-width: 100%;
				}
			}
		}
		.publisher__bgScene{
			opacity: 1;
		}
	}
	.switchWrapper{
		border-radius: 2.22222rem;
		background: $white-color;
		box-shadow: 0px 4px 20px 0px rgba(212, 217, 222, 0.25);
		display: inline-flex;
		padding: 1.77778rem 2.22222rem;
		flex-direction: column;
		align-items: center;
		gap: 1.77778rem;
		margin-top: 9rem;
		height: fit-content;
		p{
			color: $mute-color;
			font-size: 1.11111rem;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		.switchFlex{
			display: grid;
			grid-template-columns: 1fr auto 1fr;
			align-items: center;
			gap: 2.22222rem;
			h2{
				margin: 0;
				padding: 0;
				color: $mute-color;
				text-align: center;
				cursor: pointer;
				&.active{
					color: $text-color;
				}
			}
			.switch{
				display: flex;
				width: 4.88889rem;
				padding: 0.11111rem;
				align-items: flex-start;
				gap: 0.55556rem;
				border-radius: 3.33333rem;
				transition: .4s;
				span{
					width: 2rem;
					height: 2rem;
					flex-shrink: 0;
					border-radius: 2rem;
					background: $white-color;
					transform: translateX(0);
					transition: .4s;
				}
				&.seller{
					&:hover{
						background-color: #F78F8F;
					}
					span{
						transform: translateX(2.6rem);
					}
				}
				&.publisher{
					&:hover{
						background-color: #A1B6FF;
					}
					background: $main-blue-color;
				}
				&.seller{
					background: $main-red-color;
				}
			}
		}
	}
}

@media (max-width: 1260px){
	.wrapper{
		.publisher{
			&__leftBg{
				height: calc(100vh - 6.69rem);
				left: 0;
				top: 6.69rem;
			}
			&__plane{
				height: 9.95656rem;
				bottom: .63rem;
			}
			&__planeRight{
				height: calc(100vh - 10rem);
				top: 3.56rem;
				right: 10rem;
				z-index: 5;
			}
			&__planeLeft{
				height: 50%;
				position: absolute;
				bottom: 5.3rem;
				left: 40%;
			}
		}

		.seller{
			&__right{
				display: none;
			}
			&__left{
				height: 35%;
				bottom: 9.5rem;
				transform: translateX(-280%);
			}
			&__static_cloud{
				width: 10%;
				position: absolute;
				top: 38%;
				left: 2.5rem;
			}
			&__static_switcher{
				bottom: 4.5rem;
			}
			&__static_profile{
				width: 8%;
				position: absolute;
				bottom: 6rem;
				right: 6%;
			}
			&__static_notes{
				width: 8%;
				position: absolute;
				bottom: 10rem;
				left: 6%;
			}
		}

		h1{
			font-size: 2.75rem;
			margin-top: 1rem;
			max-width: 30rem;
		}
		&.seller h1 span img{
			height: 2.2rem;
		}
		&.publisher h1 span img{
				height: 2.6rem;
		}
		.switchWrapper{
			border-radius: 1rem;
			padding: 1rem;
			gap: 1rem;
			margin-top: 6.75rem;
			p{
				font-size: 1rem;
			}
			.switchFlex{
				gap: 1.5rem;
				grid-template-columns: auto auto auto;
				.switch{
					width: 3.25rem;
					padding: 0.125rem;
					border-radius: 3.75rem;
					span{
						width: 1.25rem;
						height: 1.25rem;
					}
					&.seller{
						span{
							transform: translateX(1.75rem);
						}
					}
				}
			}
		}
	}
}


@media (max-width: 768px){
	.wrapper{
		.publisherMobileImg{
			display: block;
			width: 46.75rem;
			position: absolute;
			top: 15.11rem;
			left: 50%;
			transform: translateX(-50%);
		}
		
		.sellerMobileImg{
			display: block;
			width: 40rem;
			position: absolute;
			top: 6rem;
			left: 50%;
			transform: translateX(-50%);
		}

		h1{
			font-size: 2.25rem;
			margin-top: 1.5rem;
			max-width: 25rem;
		}
		&.seller h1 span img{
			height: 2.2rem;
		}
		&.publisher h1 span img{
			height: 2.2rem;
			left: 0.1rem;
		}
		.switchWrapper{
			border-radius: 1rem;
			padding: 1.5rem;
			margin-top: 15.75rem;
			.switchFlex{
				gap: 1rem;
				.switch{
					width: 4rem;
					padding: 0.25rem;
					border-radius: 3.75rem;
					span{
						width: 1.625rem;
						height: 1.625rem;
					}
					&.seller{
						span{
							transform: translateX(1.875rem);
						}
					}
				}
			}
		}
	}
}


@media (max-width: 420px){
	.wrapper{
		.publisherMobileImg{
			width: 31.59913rem;
			top: 10.18rem;
		}
		
		.sellerMobileImg{
			width: 24.79063rem;
			top: 4.3rem;
		}

		h1{
			font-size: 2rem;
			max-width: 23rem;
		}
		&.seller h1 span img{
			height: 1.6rem;
		}
		&.publisher h1 span img{
			height: 2rem;
			left: 0;
		}
		.switchWrapper{
			padding: 1rem;
			margin-top: 12.44rem;
		}
	}
}