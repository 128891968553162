@import '~/src/styles/variables.scss';

.cardWrapper{
	flex-grow: 1;
	width: 100%;
	padding: 1rem;
	border-radius: 1rem;
	border: 1px solid $gray-100;
	background: #fff;
	height: fit-content;
}

.balanceWrapper{
	display: flex;
	flex-direction: column;
	gap: .25rem;
	height: fit-content;
}

.balance{
	color: $gray-800;
	font-size: 1.5rem;
	font-style: normal;
	font-family: 'SF Semibold';
	font-weight: 800;
	line-height: 2rem;
	letter-spacing: 0.03rem;
}

.balanceLabel{
	color: $gray-500;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem; /* 150% */
	letter-spacing: 0.02rem;
}
.fullHeight{
	height: 100%;
}
.reportsCard{
	height: 100%;
	max-width: calc(100% - 29.6875rem);
}
.cardHeader{
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $gray-800;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.025rem;
}
.reports{
	display: flex;
	flex-direction: column;
	gap: .75rem;
	margin-top: 1rem;
}
.line{
	width: 100%;
	height: 1px;
	background: $gray-100;
	margin: 1rem 0;
}
.colSm{
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	min-width: 28.4375rem;
	max-width: 28.4375rem;
}

.grid{
	display: flex;
	gap: 1.25rem;
	height: 100%;
}

.channelItem{
	display: flex;
	align-items: center;
	gap: 0.75rem;
	align-self: stretch;
	.img{
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		overflow: hidden;
		position: relative;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	a{
		color: $gray-900;
		font-size: 1rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.5rem; /* 150% */
		letter-spacing: 0.02rem;
		margin-right: auto;
		text-decoration: none;
	}
}

.channelsWrapper{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
	align-self: stretch;
}