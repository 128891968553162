.container{
	position: absolute;
	top: 0;
	left: 50%;
	width: fit-content;
	height: calc(100% - 14.61rem);
	z-index: 10;
	transform: translateX(-50%);
	svg{
		height: 100%;
		overflow: visible;
	}
}