@import '~/src/styles/variables.scss';

.fullHeight{
	height: 100%;
}

.cardHeader{
	display: flex;
	align-items: center;
	color: $gray-800;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.025rem;
	gap: .5rem;
	.btns{
		display: flex;
		gap: 0.5rem;
		margin-left: auto;
	}
}
.colSm{
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	min-width: 28.4375rem;
	max-width: 28.4375rem;
}

.grid{
	display: flex;
	gap: 1.25rem;
	height: 100%;
}

.line{
	width: 100%;
	height: 1px;
	background: $gray-100;
	margin: 1rem 0;
}

.preview{
	aspect-ratio: 423/238;
	width: 100%;
	align-self: stretch;
	border-radius: 0.5rem;
	background: #D9D9D9;
	margin-bottom: 0.75rem;
}

.content{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.75rem;
	color: $gray-800;
	align-self: stretch;
	p{
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.125rem; /* 128.571% */
		letter-spacing: 0.0175rem;
	}	
	strong{
		font-size: 0.875rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.125rem; /* 128.571% */
		letter-spacing: 0.0175rem;
	}
}
.btn{
	width: 100%;
	border-radius: 0.5rem;
	background: #F3F3F3 !important;
	color: $gray-900 !important;
	height: 2.5rem;
	margin-top: 1rem;
}

.info{
	display: flex;
	padding: 0.75rem;
	align-items: flex-start;
	gap: 1.5rem;
	align-self: stretch;
	border-radius: 0.5rem;
	border: 1px solid $gray-100;
	& > div{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.25rem;
		p{
			color: $gray-500;
			font-size: 1rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1.5rem; /* 150% */
			letter-spacing: 0.02rem;
			white-space: nowrap;
		}
		span{
			color: $gray-900;
			font-size: 1rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1.5rem; /* 150% */
			letter-spacing: 0.02rem;
			white-space: nowrap;
		}
	}
	&:not(:last-child){
		margin-bottom: 1rem;
	}
}