@import '/src/styles/variables.scss';

@keyframes opacity {
	0%{ opacity: 0; }
	100%{ opacity: 1;}
}
@keyframes fromLeft {
	0%{ transform: translateX(-50vw); }
	100%{ transform: translateX(-38%);}
}

.wrapper{
	height: 100vh;
	width: 100vw;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: $publisher-bg-color;
	background-image: url('~/public/images/publisherBG.svg');	
	display: flex;
	align-items: center;
	.successWrapper{
		height: 100vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		gap: 1.44rem;
		opacity: 0;
		animation: opacity 1s ease-in;
		h2{
			color: $text-color;
			text-align: center;
			font-family: "SF Semibold";
			font-size: 2.22222rem;
			font-weight: 600;
			padding: 0;
			margin: 0;
			margin-top: -14rem;
		}
		p{
			font-size: 1.11111rem;
			padding: 0;
			margin: 0;
		}
		a{
			position: relative;
			text-decoration: none;
			width: fit-content;
			border-radius: 2.22222rem;
			background: $text-color;
			display: flex;
			padding: 0.88889rem 3.33333rem;
			align-items: flex-start;
			gap: 0.55556rem;
			color: #FFF;
			font-family: "SF Medium";
			font-size: 1.11111rem;
			transition: .4s background-color;
			cursor: pointer;
			&:hover{	
				background-color: #436CFF;
			}
		}
		img{
			width: 100%;
			position: absolute;
			bottom: 14rem;
			left: 0;
			right: 0;
			pointer-events: none;
		}
		.plane{
			position: absolute;
			height: 13rem;
			left: 50%;
			bottom: 10rem;
			width: 100%;
			transform: translateX(-38%);
			pointer-events: none;
			animation: fromLeft 1s ease-in;
			[class="lf-player-container"]{
				height: 100%;
			}
		}
	}
	&.sended{
		.successWrapper{
			transition: opacity .5s;
			opacity: 1;
		}
	}
	.flex{
		display: flex;
		justify-content: space-between;
		align-items: center;
		h2{
			font-family: 'SF Semibold';
			font-size: 2rem;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin: 0;
			padding: 0;
		}
		p{
			color: #7B7D81;
			font-size: 1.11111rem;
			font-weight: 400;
			margin: 0;
			padding: 0;
			margin-top: 0.68rem;
		}
		form{
			margin-top: 3.3rem;
			.formFlex{
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				width: 47.22222rem;
				gap: 1.78rem 1.1rem;
			}
			.lgInput{
				width: 100%;
			}
			.smInput{
				width: 23.05556rem;
			}
			.counter{
				display: flex;
				gap: 0.44444rem;
				font-size: 0.66667rem;
				margin-top: 0.11rem;
			}
			.lgInput, .smInput{
				.inputWrapper{
					display: flex;
					align-items: center;
					display: flex;
					height: 3.22222rem;
					gap: 0.67rem;
					border-radius: 0.44444rem;
					border: 1px solid  #919396;
					background: #FFF;
					overflow: hidden;
					box-sizing: border-box;
					transition: .2s border-color;
					&.errorWrapper:not(:focus-within){
						border-color: #EE1F1F;
						.img{
							background: #FEE9E9;
						}
					}
					&:hover{
						border-color: #4F5157;
					}
					&:focus-within{
						border-color: #436CFF;
					}
				}
				.error{
					display: flex;
					align-items: center;
					gap: 0.44444rem;
					margin-top: 0.33rem;
					img{
						width: 1.33333rem;
						height: 1.33333rem;
					}
					color: #EE1F1F;
					font-size: 0.66667rem;
					font-style: normal;
					font-weight: 400;
					line-height: 0.88889rem; 
				}
				input{
					width: 100%;
					height: 100%;
					padding: 1.1rem 0;
					font-size: 0.77778rem;
					font-style: normal;
					font-weight: 400;
					line-height: 1rem;
					outline: none;
					border: none;
					background: none; 
				}
				.img{
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					padding: 0.94444rem 0;
					background: #F3F3F3;
					width: fit-content;
					img{
						width: 1.33333rem;
						height: 1.33333rem;
					}
				}
			}
			.btns{
				display: flex;
				gap: 1.78rem;
				align-items: center;
				margin-top: 1.78rem;
			}
			button{
				display: flex;
				width: 15rem;
				padding: 1.33333rem;
				justify-content: center;
				align-items: center;
				gap: 0.55556rem;
				border-radius: 2.22222rem;
				background: #698AFF;
				border: none;
				color:  #FFF;
				font-family: 'SF Medium';
				font-size: 1.33333rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-decoration: none;
				transition: .4s background-color;
				cursor: pointer;
				&:hover{	
					background-color: #436CFF;
				}
				&:disabled{
					background-color: #E9EAEA;
					color: #A7A8AB;
				}
			}
			.link{
				display: flex;
				align-items: center;
				gap: 0.66667rem;
				color: var(--blue-900, #436CFF);
				font-family: 'SF Medium';
				font-size: 1.33333rem;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				text-decoration: none;
				img{
					width: 1.33333rem;
					height: 1.33333rem;
				}
			}
			.textarea{
				width: 100%;
				.error{
					display: flex;
					align-items: center;
					gap: 0.44444rem;
					margin-top: 0.33rem;
					img{
						width: 1.33333rem;
						height: 1.33333rem;
					}
					color: #EE1F1F;
					font-size: 0.66667rem;
					font-style: normal;
					font-weight: 400;
					line-height: 0.88889rem; 
				}
				textarea{
					border-radius: 0.44444rem;
					border: 1px solid #919396;
					background: #FFF;
					padding: 0.44rem 0.89rem;
					font-size: 0.77778rem;
					font-style: normal;
					font-weight: 400;
					line-height: 1rem;
					resize: vertical;
					height: 10.44444rem;
					width: 100%;
					box-sizing: border-box;
					outline: none;
					transition: .2s border-color;
					&:hover{
						border-color: #4F5157;
					}
					&:focus{
						border-color: #436CFF;
					}
				}
			}
		}
		.img{
			max-width: 50%;
			width: 100%;
			position: relative;
			aspect-ratio: 1/1;
			display: block;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	@media (max-width: 1260px){
		.successWrapper{
			gap: 1rem;
			h2{
				font-size: 1.5rem;
			}
			p{
				font-size: .75rem;
			}
			a{
				border-radius: 2.5rem;
				padding: 0.5rem 2rem;
				font-size: 1rem;
				line-height: 1.5rem;
			}
			img{
				bottom: 7rem;
			}
			.plane{
				height: 9.33563rem;
				bottom: 3.25rem;
			}
		}
		.flex{
			h2{
				font-size: 1.5rem;
			}
			p{
				font-size: .75rem;
				margin-top: 0.5rem;
			}
			form{
				margin-top: 1rem;
				.formFlex{
					width: 25.6rem;
					gap: 1.25rem 1rem;
				}
				.smInput{
					width: 12.1875rem;
				}
				.counter{
					font-size: 0.75rem;
					margin-top: 0.13rem;
				}
				.lgInput, .smInput{
					.inputWrapper{
						height: 2.5rem;
						border-radius: 0.5rem;
					}
					.error{
						gap: 0.25rem;
						margin-top: 0.13rem;
						margin-bottom: -1.13rem;
						img{
							width: 1rem;
							height: 1rem;
						}
						font-size: 0.625rem;
						line-height: 0.75rem; 
					}
					input{
						padding: .5rem 0;
						font-size: 0.75rem;
					}
					.img{
						img{
							width: 1rem;
							height: 1rem;
						}
					}
				}
				.btns{
					gap: 1rem;
					margin-top: 1rem;
				}
				button{
					width: 9rem;
					padding: 0.5rem 2rem;
					border-radius: 2.5rem;
					font-size: 1rem;
				}
				.link{
					gap: 0.5rem;
					font-size: .75rem;
					img{
						width: 1rem;
						height: 1rem;
					}
				}
				.textarea{
					.error{
						gap: 0.25rem;
						margin-top: 0.13rem;
						margin-bottom: -1.13rem;
						img{
							width: 1rem;
							height: 1rem;
						}
						font-size: 0.625rem;
						line-height: 0.75rem; 
					}
					textarea{
						border-radius: 0.5rem;
						padding: 0.59rem 0.62rem;
						font-size: 0.627rem;
						min-height: 7.5rem;
					}
				}
			}
		}
	}
	@media (max-width: 756px) {
		height: 100vh;
		width: 100vw;
		.successWrapper{
			gap: 1rem;
			h2{
				font-size: 1.75rem;
			}
			p{
				font-size: 1rem;
			}
			a{
				border-radius: 2.5rem;
				padding: 0.5rem 2rem;
				font-size: 1.25rem;
				width: 22.56rem;
				height: 3.5rem;
				align-items: center;
				justify-content: center;
			}
			img{
				bottom: 14.94rem;
				width: 57.5rem;
				left: 40%;
				transform: translateX(-50%);
			}
			.plane{
				height: 9.33563rem;
				bottom: 10.56rem;
			}
		}
		.flex{
			display: flex;
			justify-content: space-between;
			align-items: center;
			h2{
				font-family: 'SF Regular';
				font-size: 1.75rem;
				text-align: center;
				max-width: 20rem;
				margin: 0 auto;
			}
			p{
				font-size: 1rem;
				text-align: center;
				width: 100%;
				margin-top: 1rem;
			}
			form{
				margin-top: 1.5rem;
				.formFlex{
					width: 100%;
					gap: 2.25rem;
				}
				.smInput{
					width: 100%;
				}
				.counter{
					font-size: 0.625rem;
					margin-top: 0.5rem;
				}
				.lgInput, .smInput{
					.inputWrapper{
						height: 3.5rem;
					}
					.error{
						gap: 0.5rem;
						margin-top: 0.37rem;
						margin-bottom: -1.62rem;
						img{
							width: 1.25rem;
							height: 1.25rem;
						}
						font-size: 1rem;
						line-height: 1.25rem;
					}
					input{
						padding: 1rem 0;
						font-size: 1rem;
					}
					.img{
						img{
							width: 1.5rem;
							height: 1.5rem;
						}
					}
				}
				.btns{
					gap: 1rem;
					margin-top: 2.25rem;
					flex-direction: column;
				}
				button{
					width: 100%;
					padding: 1rem 2rem;
					border-radius: 2.5rem;
					font-size: 1rem;
				}
				.link{
					gap: 0.5rem;
					font-size: 1rem;
					img{
						width: 1.5rem;
						height: 1.5rem;
					}
				}
				.textarea{
					.error{
						gap: 0.5rem;
						margin-top: 0.37rem;
						margin-bottom: -1.62rem;

						img{
							width: 1.25rem;
							height: 1.25rem;
						}
						font-size: 1rem;
						line-height: 1.25rem;
					}
					textarea{
						padding: 1rem;
						font-size: 1rem;
						min-height: 7.5rem;
					}
				}
			}
		}
	}

	@media (max-width: 420px) {
		.successWrapper{
			gap: .5rem;
			h2{
				font-size: 1.25rem;
				margin-top: -7rem;
			}
			p{
				font-size: 0.875rem;
			}
			a{
				padding: 0.75rem 1.5rem;
				font-size: 1rem;
				width: fit-content;
				height: fit-content;
				margin-top: 1rem;
			}
			img{
				top: 50%;
				width: 36.25rem;
				left: 40%;
				transform: translateX(-50%);
			}
			.plane{
				height: 5.8855rem;
				top: 50%;
				transform: translate(-30%, 3rem);
			}
		}

		.flex{
			h2{
				font-size: 1.25rem;
				max-width: 15rem;
				margin: 0 auto;
			}
			p{
				font-size: 0.875rem;
				max-width: 13rem;
				margin: 0 auto;
				margin-top: .5rem;
			}
			form{
				.formFlex{
					gap: 1.5rem;
				}
				.lgInput, .smInput{
					.inputWrapper{
						height: 3rem;
					}
					.error{
						gap: 0.25rem;
						margin-top: 0.25rem;
						margin-bottom: -1.125rem;
						img{
							width: 0.875rem;
							height: 0.875rem;
						}
						font-size: 0.75rem;
						line-height: 0.875rem;
					}
					input{
						font-size: 0.875rem
					}
				}
				.btns{
					gap: 1rem;
					margin-top: 2.25rem;
					flex-direction: column;
				}
				button{
					padding: 0.75rem 1.5rem;
					font-size: 0.875rem;
				}
				.link{
					font-size: 0.875rem;
					img{
						width: 1.25rem;
						height: 1.25rem;
					}
				}
				.textarea{
					.error{
						gap: 0.25rem;
						margin-top: 0.25rem;
						margin-bottom: -1.125rem;
						img{
							width: 0.875rem;
							height: 0.875rem;
						}
						font-size: 0.75rem;
						line-height: 0.875rem;
					}
					textarea{
						font-size: 0.875rem;
					}
				}
			}
		}
	}
}