@import '~/src/styles/variables.scss';

.wrapper{
	background: #F4F8FE;
	padding-bottom: 2rem;
	padding-top: 9.5rem;
}

.title{
	margin: 0;
	color: $gray-900;
	font-size: 2.25rem;
	font-weight: 600;
}
.subtitle{
	margin: 0;
	color: $gray-500;
	font-size: 1.25rem;
	font-weight: 400;
	margin-top: 0.75rem;
}
.flex{
	margin-top: 2rem;
	display: flex;
	gap: 1.25rem;
}

.content{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.75rem;
	width: 100%;
	.header{
		display: flex;
		align-items: center;
		white-space: nowrap;
		gap: 1.25rem;
		span{
			color: $gray-500;
			font-size: 1rem;
			line-height: 1.5rem; /* 150% */
		}
	}
}

@media (max-width: 1260px) {
	.wrapper{
		padding-top: 5.13rem;
	}
	
	.title{
		font-size: 1.25rem;
	}
	.subtitle{
		font-size: .75rem;
		margin-top: 0.25rem;
	}
	.flex{
		margin-top: 1rem;
		gap: 1rem;
	}
	
	.content{
		.header{
			gap: .75rem;
			span{
				font-size: .75rem;
			}
		}
	}	
}

@media (max-width: 820px) {
	.wrapper{
		padding-top: 5.5rem;
	}
	
	.title{
		font-size: 1.75rem;
		font-family: 'SF Regular';
		font-weight: 600;
	}
	.subtitle{
		font-size: 1rem;
		margin-top: 0.5rem;
	}
	.flex{
		margin-top: 1.5rem;
	}
	.filterBtn{
		display: flex;
		padding: 1rem;
		justify-content: center;
		align-items: center;
		gap: 1rem;
		border-radius: 0.5rem;
		border: 1px solid #E9EAEA;
		background: #FFF;
		color: $gray-900;
		font-size: 1rem;
		font-style: normal;
		font-weight: 400;
		line-height: 1.25rem; /* 125% */
		letter-spacing: 0.02rem;
		cursor: pointer;
	}
	
	.content{
		gap: 1rem;
		.header{
			gap: .5rem;
			span{
				font-size: 1rem;
			}
		}
	}	
}

@media (max-width: 420px) {
	.wrapper{
		padding-top: 4.5rem;
	}
	.title{
		font-size: 1.25rem;
	}
	.subtitle{
		font-size: .875rem;
	}

	.filterBtn{
		padding: 0.75rem 1rem;
		font-size: .875rem;
		line-height: 1rem; /* 125% */
	}
	
	.content{
		gap: 1rem;
		.header{
			flex-wrap: wrap;
			span{
				font-size: .875rem;
			}
		}
	}	
}