@import '~/src/styles/variables.scss';

.flex{
	padding-top: 9.5rem;
	display: flex;
	gap: 1.25rem;
	padding-bottom: 1.25rem;
}
.buyCard{
	max-width: 26rem;
	.button, .removeBtn{
		width: 100%;
		margin-top: 1rem;
	}
}
.content{
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
}
.row{
	display: flex;
	gap: 1.25rem;
}
.img{
	min-width: 7.5rem;
	width: 7.5rem;
	height: 7.5rem;
	border-radius: 100%;
	overflow: hidden;
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
.content{
	width: 100%;
}
.wrapper{
	background: #F4F8FE;
	width: 100%;
	min-height: 100vh;
}
.cardHeader{
	display: flex;
	align-items: center;
	color: $gray-800;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.025rem;
}

.line{
	width: 100%;
	height: 1px;
	background: $gray-100;
	margin: 1rem 0;
}

.preview{
	width: 100%;
	display: flex;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
	p{
		color: $gray-500;
		font-size: 1rem;
		font-style: normal;
		line-height: 1.125rem; /* 128.571% */
		margin: 0;
		padding: 0;
		margin-top: 0.25rem;
	}
	h2{
		color: $gray-800;
		font-size: 1.5rem;
		font-style: normal;
		font-family: 'SF Semibold';
		font-weight: 600;
		line-height: 1.75rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		margin: 0;
		span{
			display: flex;
			padding: 0.0625rem 0.375rem;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 0.625rem;
			border-radius: 0.25rem;
			background: #EDF1FF;
			color: #436CFF;
			font-size: 0.875rem;
			font-weight: 400;
			line-height: 1.375rem;
		}
	}
}
.line{
	width: 100%;
	height: 1px;
	background: $gray-100;
}
.postReach{
	max-width: calc((415/1285) * 100%);
}
.postReachNumber, .subscribersNumber{
	color: $gray-800;
	font-size: 1.5rem;
	font-style: normal;
	font-family: 'SF Semibold';
	font-weight: 700;
	line-height: 2rem; /* 133.333% */
	letter-spacing: 0.03rem;
}
.postReachList, .subscribersList{
	display: flex;
	align-items: center;
	align-self: stretch;
	margin-top: 1rem;
	li{
		display: flex;
		flex: 1 0 0;
		flex-direction: column;
		align-items: flex-start;
		gap: 0.125rem;
		p{
			color: $gray-500;
			font-size: 0.875rem;
			font-style: normal;
			font-weight: 400;
			line-height: 1.375rem; /* 157.143% */
			letter-spacing: 0.0175rem;
		}
		span{
			color: $gray-900;
			font-family: 'SF Medium';
			font-size: 1rem;
			font-style: normal;
			font-weight: 500;
			line-height: 1.5rem; /* 150% */
			letter-spacing: 0.02rem;
			&.plus{
				color: #436CFF;
			}
			&.minus{
				color: #EE1F1F;
			}
		}
	}
}

.stats{
	display: flex;
	align-items: center;
	gap: 1rem;
	align-self: stretch;
	div{
		display: flex;
		padding: 0.75rem 1rem;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 0.125rem;
		flex: 1 0 0;
		border-radius: 0.5rem;
		border: 1px solid $gray-100;
		span{
			color: $gray-500;
			font-size: 0.875rem;
			font-style: normal;
			line-height: 1rem; /* 114.286% */
			margin: 0;
		}
		p{
			margin: 0;
			color: $gray-900;
			font-size: 1.125rem;
			font-weight: 600;
			line-height: 1.625rem;
		}
	}
}

@media (max-width: 1260px) {
	.flex{
		padding-top: 5.13rem;
	}
	
	.img{
		min-width: 4.5rem;
		width: 4.5rem;
		height: 4.5rem;
	}
	.cardHeader{
		font-size: .875rem;
	}
	
	.line{
		margin: .75rem 0;
	}
	
	.preview{
		gap: .75rem;
		p{
			font-size: .75rem;
			line-height: 1rem; 
		}
		h2{
			font-size: 1rem;
			line-height: 1.25rem;
			gap: 0.25rem;
			span{
				font-size: 0.625rem;
				line-height: .75rem;
			}
		}
	}
	.postReachNumber, .subscribersNumber{
		font-size: 1rem;
		line-height: 1.25rem;
	}
	.postReachList, .subscribersList{
		margin-top: .75rem;
		li{
			p{
				font-size: 0.627rem;
				line-height: .75rem; /* 157.143% */
			}
			span{
				font-size: .75rem;
				line-height: 1rem; /* 150% */
			}
		}
	}
	
	.stats{
		gap: .75rem;
		padding-top: .75rem;
		div{
			padding: 0.25rem 1rem;
			span{
				font-size: 0.625rem;
				line-height: .75rem; /* 114.286% */
			}
			p{
				font-size: 1rem;
				line-height: 1.25rem;
			}
		}
	}
}


@media (max-width: 900px) {
	.flex{
		padding-top: 5.5rem;
		flex-direction: column;
	}

	.content{
		display: contents;
	}
	.mainInfo{
		order: 0;
	}
	.buyCard{
		order: 1;
		max-width: 100%;
	}
	.row{
		flex-direction: column;
		order: 2;
	}
	.otherStats{
		order: 3;
	}
	.postReach{
		max-width: 100%;
	}
}

@media (max-width: 420px) {
	.stats{
		flex-direction: column;
		div{
			width: 100%;
		}
	}
}