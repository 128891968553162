@import '~/src/styles/variables.scss';

.wrapper{
	width: 100vw;
	border-radius: 4.44444rem 4.44444rem 0rem 0rem;
	background: $white-color;
	z-index: 10;
	position: relative;
	.circle{
		position: sticky;
		height: calc(100vh - 10rem);
		padding-top: 8.28rem;
		top: 0;
		svg{
			height: 100%;
			position: relative;
			aspect-ratio: 1;
			width: auto;
			max-width: 48.33333rem;
			circle{
				transition: .6s;
				&.active{
					fill: #fff;
					stroke: #EE1F1F;
					stroke-width: 4px;
				}
				&.empty{
					fill: #fff;
					stroke: #A7A8AB;
					stroke-width: 2px;
				}
				&.fill{
					fill: #EDF1FF;
					stroke: #A1B6FF;
					stroke-width: 4px;
				}
			}
		}
	}
	.scroll {
		height: 700vh;
		overflow-y: scroll;
		display: flex;
		justify-content: flex-end;
		flex-wrap: wrap;
		width: 40%;
		margin-left: auto;
		margin-right: 0;
		scroll-snap-type: y mandatory;
		margin-top: calc(-100vh + 10rem);
		section{
			scroll-snap-align: start;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			width: 100%;
			margin-left: auto;
			margin-right: 0;
			height: 100vh;
			h2{
				color: #E9EAEA;
				font-family: 'SF Black';
				font-size: 7.11111rem;
				font-style: normal;
				line-height: normal;
				margin: 0;
				padding: 0;
			}
			h5{
				color: $text-color;
				text-align: center;
				font-family: 'SF Semibold';
				font-size: 3.55556rem;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin: 0;
				padding: 0;
				margin-top: 1.56rem;
				&.small{
					font-size: 2.2rem;
				}
			}
			img{
				width: 15.77778rem;
				height: 15.77778rem;
				margin-top: 3.11rem;
			}
		}
	}
}

.pathWrapper{
	width: 100vw;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	background-color: $seller-bg-color;
	background-image: url('~/public/images/sellerBG.svg');
	padding-top: 5.56rem;
	margin-top: 5.56rem;
	position: relative;
	h2{
		text-align: center;
		font-family: 'SF Semibold';
		font-size: 2.66667rem;
		color: $text-color;
	}
	.messages{
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 2.78rem;
		margin-top: 5.56rem;
		position: relative;
		z-index: 100;
		.message{
			width: fit-content;
			border-radius: 3.33333rem;
			background: #F8A5A5;
			padding: 1.77778rem 3.33333rem;
			color: $white-color;
			position: relative;
			font-size: 2rem;
			opacity: 0;
			p{
				margin: 0;
				padding: 0;
			}
			.chevron{
				width: 1.77778rem;
				height: 1.55556rem;
				position: absolute;
				left: 2.77778rem;
				bottom: -1.55556rem;
			}
			.star{
				width: 4.44444rem;
				height: 4.71639rem;
				position: absolute;
				left: -1.55556rem;
				top: -1.55556rem;
			}
			&:nth-child(1){
				margin-left: 13.72rem;
			}
			&:nth-child(2){
				align-self: flex-end;
				margin-right: 13.72rem;
				img{
					transform: scaleX(-1);
				}
				.chevron{
					left: auto;
					right: 2.77778rem;
				}
				.star{
					left: auto;
					right: -1.55556rem;
				}
			}
			&:nth-child(3){
				margin-left: 30rem;
			}
		}
	}
}

.pathContainer{
	position: absolute;
	top: 0;
	left: 13.3rem;
	width: fit-content;
	z-index: 10;
	svg{
		height: 100%;
		overflow: visible;
	}
}

.counterWrapper{
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 4.44rem;
	h2{
		text-align: center;
		font-family: "SF Semibold";
		font-size: 2.66667rem;
		color: $text-color;
		margin: 0;
		padding: 0;
	}
	.counters{
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 2.22222rem;
		div{
			display: flex;
			width: 28.88889rem;
			flex-direction: column;
			align-items: center;
			gap: 1.11111rem;
			h5{
				color: #F57878;
				text-align: center;
				font-family: "SF Black";
				font-size: 5.33333rem;
				margin: 0;
				padding: 0;
			}
			p{
				margin: 0;
				padding: 0;
				color:  $text-color;
				text-align: center;
				font-family: "SF Light";
				font-size: 1.77778rem;
			}
		}
	}
	.btnSubmit{
		display: flex;
		padding: 1.33333rem 3.33333rem;
		align-items: flex-start;
		gap: 0.55556rem;
		border-radius: 2.22222rem;
		background: #698AFF;
		color: #FFF;
		font-family: "SF Medium";
		font-size: 1.33333rem;
		width: fit-content;
		text-decoration: none;
		transition: .4s background-color;
		cursor: pointer;
		&:hover{	
			background-color: #436CFF;
		}
		&:disabled{
			background-color: #E9EAEA;
			color: #A7A8AB;
		}
	}
}

@media (max-width: 1260px) {
	.wrapper{
		border-radius: 2.5rem 2.5rem 0rem 0rem;
		.circle{
			position: sticky;
			height: calc(100vh - 4.9675rem);
			padding-top: 8.47rem;
		}
		.scroll {
			margin-top: calc(-100vh + 35rem);
			section{
				h2{
					font-size: 3.5rem;
				}
				h5{
					font-size: 1.75rem;
					margin-top: 1rem;
					&.small{
						font-size: 1.75rem;
					}
				}
				img{
					width: 8.75rem;
					height: 8.75rem;
					margin-top: 1rem;
				}
			}
		}
	}

	.pathWrapper{
		h2{
			font-size: 1.75rem;
		}
		.messages{
			gap: .94rem;
			margin-top: 4.06rem;
			.message{
				border-radius: 3.75rem;
				padding: 0.75rem 1.5rem;
				font-size: 1rem;
				.chevron{
					width: 1.125rem;
					height: 0.875rem;
					left: 2.3125rem;
					bottom: -0.875rem;
				}
				.star{
					width: 2.74994rem;
					height: 2.91825rem;
					left: -0.75rem;
					top: -0.75rem;
				}
				&:nth-child(1){
					margin-left: 11.44rem;
				}
				&:nth-child(2){
					margin-right: 9.19rem;
					.chevron{
						left: auto;
						right: 2.77778rem;
					}
					.star{
						left: auto;
						right: -1.55556rem;
					}
				}
				&:nth-child(3){
					margin-left: 16rem;
				}
			}
		}
	}

	.pathContainer{
		left: 7.56rem;
		max-width: calc(100vw - 16.81rem);
		max-height: 100vh;
		svg{
			max-width: 100% !important;
		}
	}

	.counterWrapper{
		gap: 2rem;
		h2{
			font-size: 1.75rem;
		}
		.counters{
			gap: 2.5rem;
			div{
				display: flex;
				width: 17.52083rem;
				gap: .5rem;
				h5{
					font-size: 3.5rem;
				}
				p{
					font-size: 1.25rem;
				}
			}
		}
		.btnSubmit{
			padding: .5rem 2rem;
			border-radius: 2.5rem;
			font-size: 1rem;
		}
	}
}

@media (max-width: 768px) {
	.wrapper{
		.circle{
			position: sticky;
			width: 20rem;
			height: 30.75rem;
			padding-top: 6.75rem;
			padding-bottom: 4rem;
			margin: 0 auto;
		}
		.scroll {
			margin-top: calc(-100vh + 33.3rem);
			width: 100%;
			section{
				h2{
					font-size: 2.5rem;
					margin-top: 24.5rem;
				}
				h5{
					font-size: 1.375rem;
					margin-top: .75rem;
					&.small{
						font-size: 1.375rem;
					}
				}
				img{
					width: 7.25rem;
					height: 7.25rem;
					margin-top: 1rem;
				}
			}
		}
	}

	.pathWrapper{
		padding-top: 1.5rem;
		background-image: none;
		.pathMobile{
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 0;
			bottom: 0;
		}
		.messages{
			gap: 13rem;
			margin-top: 5.94rem;
			.message{
				padding: 1rem 1.5rem;
				font-size: 1.25rem;
				opacity: 1;
				line-height: 1.5rem;
				.chevron{
					width: 1.5rem;
					height: 1.125rem;
					left: 2.3125rem;
					bottom: -1.125rem;
				}
				.star{
					width: 3.5rem;
					height: 3.71425rem;
					left: -1.1875rem;
					top: -1.375rem;
				}
				&:nth-child(1){
					margin-left: 5rem;
					p{
						min-width: 17.5rem;
					}
					transform: translateX(0);
				}
				&:nth-child(2){
					margin-right: 5rem;
					transform: translateX(0);
					p{
						min-width: 13.6875rem;
					}
					.chevron{
						left: auto;
						right: 2.25rem;
					}
					.star{
						left: auto;
						right: -1.55556rem;
					}
				}
				&:nth-child(3){
					margin-left: 5rem;
					transform: translateX(0);
				}
			}
		}
	}

	.counterWrapper{
		.counters{
			gap: 2.25rem;
			flex-direction: column;
			div{
				p{
					font-size: 1rem;
				}
			}
		}
		.btnSubmit{
			padding: 1rem 2rem;
			border-radius: 2.5rem;
			font-size: 1.25rem;
		}
	}
}

@media (max-width: 420px) {
	.wrapper{
		.circle{
			width: 21.4375rem;
			height: 28.9375rem;
			padding-top: 3.5rem;
		}
		.scroll {
			margin-top: calc(-100vh + 24rem);
			width: 100%;
			section{
				h2{
					font-size: 2.25rem;
					margin-top: 25.5rem;
				}
				h5{
					font-size: 1.25rem;
					&.small{
						font-size: 1.25rem;
					}
				}
			}
		}
	}

	.pathWrapper{
		padding-top: 2.5rem;
		h2{
			font-size: 1.25rem;
		}
		.messages{
			gap: 7rem;
			margin-top: 3.75rem;
			.message{
				padding: 0.5rem 1rem;
				font-size: 1rem;
				line-height: 1.25rem;
				.chevron{
					width: 1rem;
					height: 0.75rem;
					left: 1.5rem;
					bottom: -0.75rem;
				}
				.star{
					width: 2.1875rem;
					height: 2.32138rem;
					left: -0.75rem;
					top: -0.67856rem;
				}
				&:nth-child(1){
					margin-left: 1rem;
					p{
						min-width: 10.5rem;
					}
				}
				&:nth-child(2){
					margin-right: 1rem;
					margin-top: 1.6rem;
					p{
						min-width: 10.5rem;
					}
					.chevron{
						left: auto;
						right: 1.5rem;
					}
					.star{
						right: 0rem;
					}
				}
				&:nth-child(3){
					margin-left: 1rem;
					p{
						min-width: 15rem;
					}
				}
			}
		}
	}

	.counterWrapper{
		gap: 1.5rem;
		h2{
			font-size: 1.25rem;
		}
		.counters{
			gap: 1.5rem;
			flex-direction: column;
			div{
				h5{
					font-size: 3rem;
				}
				p{
					font-size: .875rem;
				}
			}
		}
		.btnSubmit{
			padding: 0.75rem 1.5rem;
			font-size: 1rem;
		}
	}
}